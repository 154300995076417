import { Box, Button, Grid, InputLabel, Paper, Snackbar, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { RpcInvocation, useRpcFetch } from "cooke-rpc-react";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory, useRouteMatch } from "react-router";
import { ConfirmDialog } from "./ConfirmDialog";
import { LoadingIndicator } from "./Words";
import { WysiwygEditor } from "./WysiwygEditor";
import FetchStatus from "./components/FetchStatus";
import { config } from "./config";
import { AdminWord, AdminWordExample, adminWordController } from "./generated/rpc";
import { routes } from "./routes";
import { useErrorDispatcher } from "./utils";

export function WordView() {
  const {
    params: { wordId },
  } = useRouteMatch<any>(routes.word.path)!;
  const wordQuery = useRpcFetch(adminWordController.getWord, wordId);

  if (!wordQuery.result) {
    return <FetchStatus query={wordQuery} />;
  }

  const word = wordQuery.result;
  return <WordForm initWord={word} />;
}

const WordForm = ({ initWord: initWord }: { initWord: AdminWord }) => {
  const wordId = initWord.id;

  const [word, setWord] = useState(initWord);
  useEffect(() => {
    setWord(initWord);
  }, [initWord]);

  const { dispatch, error } = useErrorDispatcher();

  const useUpdateCallback = <TState extends any[]>(
    stateCb: (...val: TState) => void,
    invoke: (...val: TState) => RpcInvocation<any>
  ) => {
    const debouncedInvoke = debounce((...val: TState) => dispatch(invoke(...val)), 500);
    return useCallback(
      (...val: TState) => {
        stateCb(...val);
        debouncedInvoke(...val);
      },
      [wordId]
    );
  };

  const updateExplanation = useCallback(
    (html: string) => dispatch(adminWordController.updateWord(wordId, html)),
    [wordId]
  );

  const updateExample = useUpdateCallback(
    (id, newExp) =>
      setWord((p) => ({
        ...p,
        examples: p.examples.map((ex) => (ex.id === id ? { ...ex, ...newExp } : ex)),
      })),
    (exId: number, newExp: AdminWordExample) =>
      adminWordController.updateWordExample(exId, {
        content: newExp.content,
        name: newExp.name,
      })
  );

  const addExample = async () => {
    const exampleId = await dispatch(adminWordController.createWordExample(wordId));
    setWord({
      ...word,
      examples: [...word.examples, { id: exampleId, content: "", name: "" }],
    });
  };

  const removeExample = async (id: number) => {
    setWord({
      ...word,
      examples: word.examples.filter((x) => x.id !== id),
    });
    await dispatch(adminWordController.removeWordExample(id));
  };

  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const history = useHistory();
  const [cacheStamp, setCacheStamp] = useState(Date.now());
  const [imageError, setImageError] = useState(false);

  return (
    <>
      <ConfirmDialog
        onCancel={() => setShowConfirmRemove(false)}
        onConfirm={async () => {
          await dispatch(adminWordController.removeWord(wordId));
          history.replace(routes.words.path);
        }}
        show={showConfirmRemove}
      />
      <Snackbar open={!!error}>
        <Alert severity="error">Misslyckades att uppdatera</Alert>
      </Snackbar>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs>
          <Typography variant="h4">{word.name}</Typography>
        </Grid>
        <Grid item>
          <Box textAlign="right">
            <Button color="secondary" onClick={() => setShowConfirmRemove(true)}>
              Ta bort ord
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {imageError || !word.hasImage ? (
            <Alert severity="warning">Ingen bild</Alert>
          ) : (
            <img
              src={config.imagesUrl + "/" + wordId + ".jpg#" + cacheStamp}
              width={300}
              onError={() => setImageError(true)}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <UploadWordImageButton
            wordId={word.id}
            onUploaded={() => {
              setWord((p) => ({ ...p, hasImage: true }));
              setImageError(false);
              setCacheStamp(Date.now());
            }}
          >
            Ladda upp bild
          </UploadWordImageButton>

          {word.hasImage ? (
            <DeleteWordImageButton
              wordId={word.id}
              onDeleted={() => {
                setImageError(false);
                setCacheStamp(Date.now());
                setWord((p) => ({ ...p, hasImage: false }));
              }}
            >
              Ta bort bild
            </DeleteWordImageButton>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box p={3} display="flex">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <WysiwygEditor initialHtml={word.explanation} onDeboucedUpdate={updateExplanation} />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {word.examples.length > 0 ? (
            <Box mb={3}>
              <Typography variant="h5">Exempel</Typography>
            </Box>
          ) : null}
          <Grid container spacing={2}>
            {word.examples.map((example) => (
              <Grid item xs={12} key={example.id}>
                <ExampleForm
                  example={example}
                  onUpdate={(partial) => updateExample(example.id, { ...example, ...partial })}
                  onRemove={() => removeExample(example.id)}
                ></ExampleForm>
              </Grid>
            ))}
          </Grid>
          <Box mt={3} textAlign="right">
            <Button variant="contained" color="primary" onClick={addExample}>
              Lägg till exampel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export const UploadWordImageButton = (props: { wordId: string; onUploaded: () => void; children: React.ReactNode }) => {
  const uploadInputElement = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Button onClick={() => uploadInputElement.current?.click()}>
        {loading ? <LoadingIndicator /> : props.children}
      </Button>

      <input
        style={{ display: "none" }}
        type="file"
        ref={uploadInputElement}
        placeholder="Upload"
        multiple
        onChange={async (ev) => {
          const files = ev.currentTarget.files;

          if (files && files.length > 0) {
            setLoading(true);
            try {
              await uploadWordImage(files[0], props.wordId);
              await new Promise((resolve) => setTimeout(resolve, 500));
            } finally {
              setLoading(false);
            }
          }

          if (uploadInputElement.current) {
            uploadInputElement.current.value = "";
          }

          props.onUploaded();
        }}
      />
    </>
  );
};

async function uploadWordImage(file: File, wordId: string) {
  try {
    const response = await fetch(config.serverUrl + "/images/" + wordId, {
      mode: "cors",
      method: "PUT",
      body: file,
      credentials: "include",
    });

    if (!response.ok) {
      console.error(`Failed to upload file: ${file.name}. Aborting: `, response.statusText);
      return;
    } else {
      console.info(`Uploaded ${file.name}`);
    }
  } catch (error) {
    console.error(`Failed ot upload file: ${file.name}. Aborting: `, error);
    return;
  }
}

export const DeleteWordImageButton = (props: { wordId: string; onDeleted: () => void; children: React.ReactNode }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Button
        onClick={async () => {
          setLoading(true);
          try {
            await deleteWordImage(props.wordId);
          } finally {
            setLoading(false);
          }

          props.onDeleted();
        }}
      >
        {loading ? <LoadingIndicator /> : props.children}
      </Button>
    </>
  );
};

async function deleteWordImage(wordId: string) {
  try {
    const response = await fetch(config.serverUrl + "/images/" + wordId, {
      mode: "cors",
      method: "DELETE",
      credentials: "include",
    });

    if (!response.ok) {
      console.error(`Failed to delete word image: ${wordId}. Aborting: `, response.statusText);
      return;
    } else {
      console.info(`Delete word image ${wordId}`);
    }
  } catch (error) {
    console.error(`Failed to delete word image: ${wordId}. Aborting: `, error);
    return;
  }
}

export const ExampleForm = ({
  example,
  onUpdate,
  onRemove,
}: {
  example: AdminWordExample;
  onUpdate: (partial: Partial<AdminWordExample>) => void;
  onRemove: () => void;
}) => {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);

  return (
    <>
      <Paper>
        <Box p={3} display="flex">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Exempel"
                variant="filled"
                fullWidth
                value={example.name}
                onChange={(ev) => onUpdate({ name: ev.currentTarget.value })}
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Beskrivning</InputLabel>
              <WysiwygEditor initialHtml={example.content} onDeboucedUpdate={(html) => onUpdate({ content: html })} />
            </Grid>

            <Grid item xs={12}>
              <Box textAlign="right">
                <Button onClick={() => setShowConfirmRemove(true)}>Ta bort</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <ConfirmDialog onCancel={() => setShowConfirmRemove(false)} onConfirm={onRemove} show={showConfirmRemove} />
    </>
  );
};
