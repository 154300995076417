import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useRpc, useRpcFetch } from "cooke-rpc-react";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router";
import FetchStatus from "./components/FetchStatus";
import { adminWordController } from "./generated/rpc";
import { routes } from "./routes";

export function WordsTable() {
  const wordsQuery = useRpcFetch(adminWordController.getWordList);
  const [showAddWord, setShowAddWord] = useState(false);
  const history = useHistory();

  if (!wordsQuery.result) {
    return <FetchStatus query={wordsQuery} />;
  }

  const words = wordsQuery.result;
  return (
    <>
      <Box textAlign="right" mb={3}>
        <Button color="primary" variant="contained" onClick={() => setShowAddWord(true)}>
          Lägg till ord
        </Button>
      </Box>
      {wordsQuery.result.length === 0 ? (
        <>Det finns ännu inga ord tillagda</>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ord</TableCell>
                <TableCell>Bild</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {words.map((row) => (
                <TableRow
                  hover
                  key={row.name}
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(generatePath(routes.word.path, { wordId: row.id }))}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.hasImage ? "Ja" : "Nej"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <AddWordDialog show={showAddWord} onClose={() => setShowAddWord(false)} />
    </>
  );
}

export const AddWordDialog = (props: { show: boolean; onClose: () => void }) => {
  const [newWord, setNewWord] = useState("");
  const addWordMutation = useRpc(adminWordController.createWord);

  const history = useHistory();

  useEffect(() => {
    setNewWord("");
    addWordMutation.setResult(undefined);
    addWordMutation.setError(undefined);
  }, [props.show]);

  return (
    <Dialog open={props.show} onClose={props.onClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Lägg till ord</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Ord"
          type="email"
          fullWidth
          value={newWord}
          onChange={(ev) => setNewWord(ev.currentTarget.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={addWordMutation.invoking} onClick={props.onClose} color="primary">
          Avbryt
        </Button>
        <Button
          disabled={addWordMutation.invoking}
          onClick={async () => {
            const wordId = generateWordId(newWord);
            await addWordMutation.invoke({
              word: newWord,
              id: wordId,
            });

            history.push(generatePath(routes.word.path, { wordId }));
          }}
          color="primary"
        >
          {addWordMutation.invoking ? <CircularProgress></CircularProgress> : <>Lägg till</>}
        </Button>
      </DialogActions>
      {addWordMutation.error ? (
        <Snackbar open>
          <Alert severity="error">{addWordMutation.error.message || addWordMutation.error.code}</Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  );
};

function generateWordId(word: string) {
  return word
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\W/g, "")
    .replace(" ", "")
    .toLowerCase();
}

export function LoadingIndicator() {
  return <CircularProgress />;
}

export function ErrorMessage(props: { children: React.ReactNode }) {
  return <Alert severity="error">{props.children}</Alert>;
}
